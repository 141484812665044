export const URL_CORPORATE_TOP = 'https://corp.antaa.jp';
export const URL_CORPORATE_TERMS = 'https://corp.antaa.jp/terms';
export const URL_CORPORATE_PRIVACY_POLICY =
  'https://corp.antaa.jp/privacypolicy';
export const URL_COPYRIGHT_INFRINGEMENT_FORM =
  'https://docs.google.com/forms/d/e/1FAIpQLSfWaISiyTxGyarkwU91rIUafQzSb_g4mVzPnboybvibrXEKrA/viewform';
export const URL_INQUIRY_FORM = 'https://note.com/antaa/n/n3ae2566cdbb4';
export const URL_INQUIRY_FORM_MULTI =
  'https://docs.google.com/forms/d/1SU0mVpUakAKQ9dhc0M7epJKghj93OFD04fR7Ez6_OrY/viewform';
const FOOTER_STORE_UTM = '&utm_source=antaa_slide_web&utm_medium=footer_link';
export const URL_FIRST_POST_SUPPORT_FORM =
  'https://forms.gle/wCD3k9Ucz82yp3YH6';
export const URL_STORE_GOOGLE = `https://play.google.com/store/apps/details?id=jp.antaa.qa.android.AntaaQa${FOOTER_STORE_UTM}`;
export const URL_STORE_APPLE = `https://apps.apple.com/jp/app/id1151798574?${FOOTER_STORE_UTM}`;
export const URL_IP_GUIDELINE_VIDEO =
  'https://antaa-public.s3.ap-northeast-1.amazonaws.com/slide/ip-guideline/20220817_slide_guideline_archive/20220817_slide_guideline_archive.m3u8';

export const TWITTER_ACCOUNT_CORPORATE = '@antaa__jp';

export const URL_ANTAA_FACEBOOK = 'https://www.facebook.com/antaa';

export const URL_ANTAA_TWITTER = 'https://twitter.com/antaa__jp';

export const DEFAULT_TITLE = 'Antaa Slide';

export const ARTICLE_PARTIAL_DISCLOSE_PAGE_COUNT = 3;

export const DEPARTMENT_ID_OTHER = 27;
export const DEPARTMENT_ID_OTHER_STRING = '27';

export const ARTICLE_LIKE_ANNOUNCE_NAME_LENGTH_LIMIT = 30;

export const ONDEMAND_PROFILE_UPDATE_STATUS_MAP = {
  SKIPPED: 'SKIPPED',
  INCOMPLETE: 'INCOMPLETE',
  UPDATED: 'UPDATED',
  DIDNT_UPDATE: 'DIDNT_UPDATE',
  INCOMPLETE_TWICE: 'INCOMPLETE_TWICE',
};

export const HOSPITAL_MAX_FETCH_NUM = 100;

export const AUTHOR_MAX_FETCH_NUM = 100;

export const POPULAR_AUTHOR_MAX_FETCH_NUM = 9;

export const REQUIRED_PRIVACY_POLICY_VERSION = '20240524';
export const REQUIRED_TERMS_OF_SERVICE_VERSION = '20231228';
export const REQUIRED_TERMS_OF_ANTAA_AI_ASSISTANT_VERSION = '20240409';
export const REQUIRED_COMMUNITY_GUIDELINE_VERSION = '20240516';

export type Special = {
  name: string;
  link: string;
  mainImage: string;
  horizontalBannerImage?: string;
  gaAction?: string;
  mobileBannerImage?: string;
  alt: string;
  tags: string[];
  /**
   * スライド詳細の関連スライドを特集のtagsで取得する場合はtrue
   */
  relatedSlidesPreferSpecialTags?: boolean;
};

export type SpecialHasMobileBanner = Special & {
  mobileBannerImage: string;
};

export type SpecialsKey =
  | 'hyperkalemia'
  | 'residenceNewApartment'
  | 'hyperuricemia'
  | 'career'
  | 'communityMedicalCare'
  | 'privateHealthcare'
  | 'homeMedicalCare';

export const SPECIALS: {
  [key in SpecialsKey]: Special;
} = {
  hyperkalemia: {
    name: 'hyperkalemia',
    alt: '高カリウム血症特集',
    link: '/special/fec15a463248d6a9',
    mainImage: '/images/special/hyperkalemia_main_500*281.png',
    horizontalBannerImage: '/images/special/hyperkalemia_banner_728*90.png',
    mobileBannerImage: '/images/special/hyperkalemia_banner_1200*240.png',
    tags: [
      '高カリウム血症',
      '高K血症',
      '腎不全',
      '糖尿病性ケトアシドーシス',
      '腎性糖尿病',
      '利尿薬',
      '高血圧',
      '人工透析',
      '血液透析',
      'ロケルマ',
      'アドレナリン作動薬',
      'エース阻害薬 / ARB薬',
      '代謝性アシドーシス',
      '心電図',
      'アルドステロン拮抗薬',
      '高カリウム',
      'カリウム',
      'カリウム吸着薬',
      'CKD',
      '慢性腎臓病',
      '心不全',
      'ケイキサレート',
      'アーガメイト',
    ],
  },
  residenceNewApartment: {
    name: 'residence_new_apartment',
    alt: '居住用新築マンション特集',
    link: '/special/residence-new-apartment',
    mainImage:
      '/images/special/residence-new-apartment_main_500*281.png?ver=202407',
    horizontalBannerImage:
      '/images/special/residence-new-apartment_banner_1456*180.png',
    gaAction: 'suumo_banner',
    mobileBannerImage:
      '/images/special/residence-new-apartment_banner_1200*240.png?ver=202407',
    tags: ['居住用新築マンション', '不動産'],
  },
  hyperuricemia: {
    name: 'hyperuricemia',
    alt: '高尿酸血症特集',
    link: '/special/hyperuricemia',
    mainImage: '/images/special/hyperuricemia_main_1300*720.png',
    horizontalBannerImage: '/images/special/hyperuricemia_banner_728*90.png',
    mobileBannerImage: '/images/special/hyperuricemia_banner_1200*240.png',
    tags: [
      '高尿酸血症',
      '関節痛',
      '結晶性関節炎',
      '痛風',
      '尿酸',
      '尿酸検査',
      '血液検査',
      '尿検査',
      '尿酸降下薬',
      'URAT1阻害薬',
      'ユリス',
      'ベネシッド',
      'ユリノーム',
      'ウリアデック',
      'ザイロリック',
      'フェブリク',
      'コルヒチン',
      'ラスリテック',
      'ウラリット',
      '腎臓結石',
      '高血圧',
      '代謝症候群',
      '心血管疾患',
    ],
  },
  privateHealthcare: {
    name: 'privateHealthcare',
    alt: '【新たな選択肢】保険外診療特集',
    link: '/special/private-healthcare',
    mainImage: '/images/special/private-healthcare_main_1300*720.png',
    tags: ['保険外診療', '自由診療', '自費診療'],
  },
  homeMedicalCare: {
    name: 'home-medical-care',
    alt: '在宅医療特集',
    link: '/special/home-medical-care',
    mainImage: '/images/special/home-medical-care_main_1300*720.png',
    tags: ['在宅', '在宅医療'],
    relatedSlidesPreferSpecialTags: true,
  },
  career: {
    name: 'career',
    alt: '【キャリアWEBメディア】医師の「はたらく」を一緒に考える',
    link: 'https://forms.gle/KtoXacvo7CyuJ7kYA/',
    mainImage: '/images/career_main_20241028.png',
    tags: ['キャリア'],
  },
  communityMedicalCare: {
    name: 'communityMedicalCare',
    alt: '教科書に書いていないことを地域から学ぶ、それが離島医療',
    link: 'https://career.antaa.jp/archives/1195/',
    mainImage: '/images/career_community_main_500*266.jpg',
    tags: ['地域医療'],
  },
};

export type MobileSpecialBar = {
  key: 'career' | 'partners';
  image: string;
  alt: string;
  link: string;
  tags: string[];
  // バナー画像のデフォルトサイズを1200x240とし、縦横非が違う場合はwidth, heightを指定する
  width?: number;
  height?: number;
};

export const MOBILE_SPECIAL_BARS: MobileSpecialBar[] = [
  {
    key: 'partners',
    image: '/images/banner_hakuseikai_partners.png',
    alt: '医療法人 白青会 パートナーシップ制度',
    link: 'https://hakuseikai-partners.com/',
    tags: ['パートナーシップ制度'],
    width: 720,
    height: 202,
  },
  {
    key: 'career',
    image: '/images/career_banner_20241028.png',
    alt: SPECIALS.career.alt,
    link: SPECIALS.career.link,
    tags: SPECIALS.career.tags,
  },
];

type TopSpecialElement = {
  trackingId: string;
  imgSrc: string;
  linkUrl: string;
  description: string;
};

export const TOP_SPECIAL_ELEMENTS: TopSpecialElement[] = [
  {
    trackingId: '145',
    imgSrc: '/images/carousel_img_20241101_home_med.png',
    linkUrl: '/special/home-medical-care',
    description: '在宅医療特集',
  },
  {
    trackingId: '141',
    imgSrc: '/images/carousel_img_20240624_hyperuricemia.png',
    linkUrl: '/special/hyperuricemia',
    description: '高尿酸血症特集',
  },
  {
    trackingId: '139',
    imgSrc: '/images/carousel_img_20240625_residence-new-apartment.png',
    linkUrl: '/special/residence-new-apartment',
    description: '【医師のライフスタイル】居住用新築マンション特集',
  },

  {
    trackingId: '147',
    imgSrc: '/images/carousel_img_20241129_cristmas_special_2024.png',
    linkUrl: 'https://note.com/antaa/n/n8fa34a558e22',
    description: '【投稿者へプレゼント🎁】クリスマス関連の投稿募集中！',
  },

  {
    trackingId: '143',
    imgSrc: '/images/carousel_img_20240905_dynamics2024.png',
    linkUrl: '/special/dynamics2024',
    description: 'ダイナミクス全国大会2024特集',
  },
  {
    trackingId: '142',
    imgSrc: '/images/career_20240628.png',
    linkUrl: 'https://career.antaa.jp/',
    description: '【キャリアWEBメディア】医師の「はたらく」を一緒に考える',
  },
  {
    trackingId: '76',
    imgSrc: '/images/carousel_img_20230124_antaa_slide_academy_lp.png',
    linkUrl: '/docs/academy',
    description: '【Antaa Slide Academy】スライド投稿のノウハウを無料で学ぶ',
  },
  {
    trackingId: '135',
    imgSrc: '/images/carousel_img_20240425_hyperkalemia_az.png',
    linkUrl: '/special/fec15a463248d6a9',
    description: '【Slide募集中】高カリウム血症',
  },
  {
    trackingId: '144',
    imgSrc: '/images/carousel_img_20240926_private_healthcare.png',
    linkUrl: '/special/private-healthcare',
    description: '【新たな選択肢】保険外診療特集',
  },
];
